import { createGlobalState } from "@vueuse/core"
import { ref } from "vue"

const blurNames = createGlobalState(
  () => {
    return ref(false)
  }
)

export {
  blurNames,
}
